import { methods } from '../utils';
import { authenticatedService } from './middleware';

const {GET} = methods;
const routes = {
  getUser: id => `/users/${id}`,
};

export default {
  getUser: id => authenticatedService(GET, routes.getUser(id), {}, {}),
};
