import React, { PureComponent } from 'react';
import { icons } from '../../../../assets';
import { Button, ButtonColors, IconInput } from '../../../components';
import './form.scss';

export class Form extends PureComponent {

  state = {
    id: null,
    password: null,
    idInvalid: false,
    passwordInvalid: false,
    loginTriggered: false,
  };

  getValidityClass = isInvalid => {
    return this.state.loginTriggered ? isInvalid ? 'invalid' : 'valid' : '';
  };

  handleKeyPressed = (e) => {
    if ((e.keyCode ? e.keyCode : e.which) === 13) {
      e.preventDefault();
      const {id, password} = this.state;
      this.handleLogin(id, password);
    }
  };

  handleLogin = (id, password) => {
    const {action} = this.props;
    const idInvalid = !id || id.length === 0;
    const passwordInvalid = !password || password.length < 6;

    this.setState({
      loginTriggered: true,
      idInvalid,
      passwordInvalid,
    });

    if (!idInvalid && !passwordInvalid) {
      action(id, password);
    }
  };

  handleValueChanged = (tag, value) => {
    this.resetValidator();
    this.setState({[tag]: value});
  };

  resetValidator = () => {
    if (this.state.loginTriggered) {
      this.setState({
        idInvalid: false,
        passwordInvalid: false,
        loginTriggered: false,
      });
    }
  };

  render() {
    const {strings} = this.props;
    const {id, password, idInvalid, passwordInvalid} = this.state;

    return (
      <form className={'form-wrapper'} onKeyPress={this.handleKeyPressed}>
        <IconInput
          label={strings('identifier')}
          placeholder={strings('email')}
          tag={'id'}
          inputClassName={`id ${this.getValidityClass(idInvalid)}`}
          icon={icons.MAIL}
          handleValueChanged={this.handleValueChanged}
        />
        <IconInput
          label={strings('password')}
          type={'password'}
          tag={'password'}
          inputClassName={`password ${this.getValidityClass(passwordInvalid)}`}
          icon={icons.EYE}
          handleValueChanged={this.handleValueChanged}
        />
        <Button
          color={ButtonColors.green}
          label={strings('access')}
          className={'login'}
          action={() => this.handleLogin(id, password)}
        />
      </form>
    );
  }
}
