import { ChangePassword, Login, ResetPassword, Redirect, Register } from '../ui/screens';
import { Routes } from '../utils';

export default {
  resetPassword: {
    path: Routes.RESET_PASSWORD,
    component: ResetPassword,
    auth: false,
  },
  changePassword: {
    path: Routes.CHANGE_PASSWORD,
    component: ChangePassword,
    auth: false,
  },
  initPassword: {
    path: Routes.INIT_PASSWORD,
    component: ChangePassword,
    auth: false,
  },
  register: {
    path: Routes.REGISTER,
    component: Register,
    auth: false,
  },
  redirect: {
    path: Routes.REDIRECT,
    component: Redirect,
    auth: true,
  },
  login: {
    path: Routes.DEFAULT,
    component: Login,
    auth: false,
  },
};
