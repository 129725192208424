import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { Routes } from '../utils';
import { history } from './history';
import screens from './screens';
import store from './store';


const authRoutes = () =>
  Object.entries(screens)
    .filter(([_, value]) => value.auth)
    .map(([key, value]) => (
      <AuthRoute
        exact
        path={value.path}
        component={value.component}
        key={key}
      />
    ));

const unAuthRoutes = () =>
  Object.entries(screens)
    .filter(([_, value]) => !value.auth)
    .map(([key, value]) => (
      <Route exact path={value.path} component={value.component} key={key} />
    ));

// Authenticated routes
const AuthRoute = ({ component: Component, ...rest }) => {
  const { accessToken } = store.getState().auth;
  //usePageViews();

  return (
    <Route
      {...rest}
      render={props =>
        accessToken ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: Routes.DEFAULT_ROUTE,
              search: rest.location.search,
            }}
          />
        )
      }
    />
  );
};

// Route that can't be reached once authenticated
// const UnAuthRoute = ({ component: Component, ...rest }) => {
//   const { accessToken } = store.getState().auth;
//   console.log('STORE : ', store.getState().auth);
//   return (
//     <Route
//       render={props =>
//         accessToken ? (
//           <Redirect
//             to={{ pathname: Routes.REDIRECT, search: rest.location.search }}
//           />
//         ) : (
//           <Component {...props} />
//         )
//       }
//     />
//   );
// };

export default function Router() {
  //usePageViews();
  return (
    <ConnectedRouter history={history}>
      <Switch>
        {authRoutes()}
        {unAuthRoutes()}
      </Switch>
    </ConnectedRouter>
  );
}
