import React, { PureComponent } from 'react';
import { AuthWrapper } from '../../../components';
import { Form as ResetPasswordForm } from './Form';

export class ResetPassword extends PureComponent {

  handleResetPassword = (email) => {
    const {resetPassword} = this.props;
    resetPassword(email);
  };

  render() {
    const {strings} = this.props;
    return (
      <AuthWrapper
        label={strings('reset_password')}
        title={strings('reset_password')}
        className={'reset-password-form'}
      >
        <ResetPasswordForm
          action={this.handleResetPassword}
          strings={strings}
        />
      </AuthWrapper>
    );
  }
}
