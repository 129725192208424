import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { AuthActions } from '../../../../redux/auth';
import { ResetPassword } from './ResetPassword';

const mapStateToProps = state => ({
  strings: getTranslate(state.locale),
});

const mapDispatchToProps = dispatch => ({
  resetPassword: (email) => dispatch(AuthActions.resetPasswordRequested(email, 'formulaire')),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
