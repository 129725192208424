import { methods } from '../utils';
import { authenticatedService, service } from './middleware';

const {POST, PUT} = methods;
const routes = {
  login: '/login',
  generateTokens: '/generate',
  logout: '/logout',
  changePassword: '/login/change-password',
  initPassword: '/login/init-password',
  resetPassword: '/login/reset',
  register: '/inscription/freemium',
};

export default {
  login: (login, password, origin, redirectUrl) =>
    service(POST, routes.login, {login, password, origin, redirectUrl}),

  generateTokens: (domain, redirectUrl, comeFromLogin) =>
    authenticatedService(POST, routes.generateTokens, {domain, redirectUrl, comeFromLogin}),

  logout: () =>
    authenticatedService(POST, routes.logout),

  changePassword: (token, password) =>
    service(POST, routes.changePassword, {password}, {},
      {Authorization: `Bearer ${token}`}),

  initPassword: (token, password) =>
    service(POST, routes.initPassword, {password}, {},
      {Authorization: `Bearer ${token}`}),

  resetPassword: (email, callFrom) => {
    return service(POST, routes.resetPassword, {email, callFrom})
  },

  register: ({form}) => service(PUT, routes.register, {...form}),

};
