import { levelColor as messageLevel } from '../../ui/components/snackBar/levelColor';
import { createAction, SNACKBAR_TIMEOUT } from '../../utils';

// Types
export const types = {
  SET_MESSAGE: 'SET_MESSAGE',
  SET_ACTION: 'SET_ACTION',
  ON_ACTION: 'ON_ACTION',
  DISPLAY_SNACK_BAR: 'DISPLAY_SNACK_BAR',
  DISPLAY_SNACK_BAR_REDIRECT: 'DISPLAY_SNACK_BAR_REDIRECT',
  DISPLAY_ERROR: 'DISPLAY_ERROR',
  DISPLAY_WARNING: 'DISPLAY_WARNING',
  DISPLAY_INFO: 'DISPLAY_INFO',
  DISPLAY_SUCCESS: 'DISPLAY_SUCCESS',
  HIDE_SNACK_BAR: 'HIDE_SNACK_BAR',
};

// Actions
export default {
  setMessage: (message, level) =>
    createAction(types.SET_MESSAGE, { message, level }),
  setAction: (snackAction, actionMessage) =>
    createAction(types.SET_ACTION, { snackAction, actionMessage }),

  onAction: () => createAction(types.ON_ACTION, {}),
  displaySnackBar: () => createAction(types.DISPLAY_SNACK_BAR),

  displayError: (message, timeout = SNACKBAR_TIMEOUT) =>
    createAction(types.DISPLAY_ERROR, {
      message,
      level: messageLevel.ERROR,
      timeout,
    }),

  displayInfo: (message, timeout = SNACKBAR_TIMEOUT) =>
    createAction(types.DISPLAY_INFO, {
      message,
      level: messageLevel.INFO,
      timeout,
    }),
  displayInfoRedirect: (message, timeout = SNACKBAR_TIMEOUT) =>
    createAction(types.DISPLAY_SNACK_BAR_REDIRECT, {
      message,
      level: messageLevel.ERROR,
      timeout,
    }),
  displaySuccess: (message, timeout = SNACKBAR_TIMEOUT) =>
    createAction(types.DISPLAY_INFO, {
      message,
      level: messageLevel.SUCCESS,
      timeout,
    }),

  displayWarning: (message, timeout = SNACKBAR_TIMEOUT) =>
    createAction(types.DISPLAY_WARNING, {
      message,
      level: messageLevel.WARNING,
      timeout,
    }),

  hideSnackBar: () => createAction(types.HIDE_SNACK_BAR),
};
