import React, { Component } from 'react';
import './dropDown.scss';

export class DropDown extends Component {
  state = {
    displayList: false,
  };

  boxRef = React.createRef();

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.boxRef && this.boxRef.current && !this.boxRef.current.contains(event.target)) {
      this.setState({
        displayList: false,
      });
      event.stopPropagation();
    }
  };

  handleClickBox = () => {
    const {displayList} = this.state;
    this.setState({
      displayList: !displayList,
    });
  };

  handleClickItem = item => () => {
    const {label, handleClickItem} = this.props;

    handleClickItem({key: label, value: item});

    const {displayList} = this.state;
    this.setState({
      displayList: !displayList,
    });
  };

  render() {
    const {className, label, data, strings, disabled} = this.props;
    let { current } = this.props;
    const {displayList} = this.state;
    
    if (current === 'MR') current = 'Monsieur';
    if (current === 'MME') current = 'Madame';

    return (
      <div className={`drop-down ${className}`}>
        {label && <p className={'label'}>{strings(label)}</p>}
        <div className={'selector-container'}>
          <div
            className={`box ${disabled ? 'disabled' : ''}`}
            onClick={() => !disabled ? this.handleClickBox() : undefined}
            ref={this.boxRef}>
            <p className={'current'}>{current === '' ? strings('select') : current}</p>
            <div className={'arrow ' + (displayList ? 'up' : '')}/>
          </div>
          <div
            className={`list ${displayList && 'display'}`}>
            {data.map((d, i) =>
              <div key={i} className={`row`}
                   onClick={this.handleClickItem(d)}>
                <p>{d}</p>
              </div>,
            )}
          </div>
        </div>
      </div>
    );
  }
}
