import PropTypes from 'prop-types';
import React from 'react';
import { logger } from '../../../utils';
import './button.scss';
import { ButtonColors } from './Colors';

export const Button = ({action, color, label, icon, className, enabled = true}) => (
  <div
    onClick={() => enabled ? action() : null}
    className={`button ${color} ${className} ${enabled ? '' : 'disabled'}`}
  >
    <span>{label}</span>
    {icon && <i className="material-icons">{icon}</i>}
  </div>
);

Button.propsType = {
  label: PropTypes.string.isRequired,
  color: PropTypes.oneOf([...Object.values(ButtonColors)]),
  icon: PropTypes.string,
  action: PropTypes.func.isRequired,
};

Button.defaultProps = {
  color: ButtonColors.green,
  action: () => logger.warn('action not implemented.'),
};
