import { methods } from '../utils';
import { service } from './middleware';

const {GET} = methods;
const routes = {
  regions: '/inscription/regions',
};

export default {
  getRegions: () => service(GET, routes.regions, {}, {}),
};
