import { createAction } from '../../utils/index';

// Types
export const types = {
  USER_REQUEST: 'USER_REQUEST',
  USER_FETCHED: 'USER_FETCHED',
};

// Actions
export default {
  userRequest: () => createAction(types.USER_REQUEST),
  userFetched: user => createAction(types.USER_FETCHED, {user}),
};
