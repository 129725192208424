import { createReducer } from 'reduxsauce';
import { types } from './actions';

const initialState = {
  id: null,
  status: null,
  role: null,
};

// region Reducers
const userFetched = (state = initialState, action) => {
  const {user} = action;
  return {...state, ...user};
};
// endregion

export default createReducer(initialState, {
  [types.USER_FETCHED]: userFetched,
});
