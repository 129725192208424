import { origins } from './index';

export const getRedirectUrlFromOrigin = (origin) => {
  switch (origin) {
    case origins.KUMULUS:
      return process.env.REACT_APP_KUMULUS_URL + '/#/live?sso=epa';
    case origins.DASHBOARD:
      return process.env.REACT_APP_DASHBOARD_URL;
    case origins.FACILITATOR:
      return process.env.REACT_APP_BO_URL;
    default:
      return;
  }
};

