import React, { PureComponent } from 'react';
import { images, icons } from '../../../assets';
import {
  getRedirectUrlFromOrigin,
  origins,
  userRole,
  civilityValues,
} from '../../../utils';
import { Button, ButtonColors } from '../../components';
import './redirect.scss';

export class Redirect extends PureComponent {
  componentDidMount() {
    this.props.userRequest();
  }

  handleRedirection = domain => {
    const { generateTokens } = this.props;
    generateTokens(domain, getRedirectUrlFromOrigin(domain));
  };

  getRole = role => {
    const { strings } = this.props;

    switch (role) {
      case userRole.MENTOR:
        return strings('mentor');
      case userRole.SUPERVISOR:
        return strings('supervisor');
      case userRole.STUDENT:
        return strings('student');
      case userRole.FACILITATOR:
        return strings('facilitator');
      case userRole.ADMIN:
        return strings('administrator');
      default:
        return '';
    }
  };

  render() {
    const {
      hasUserAccessToDashboard,
      hasUserAccessToBO,
      strings,
      user,
      logout,
    } = this.props;
    const civility =
      user.civility === civilityValues.MR ? strings('mr') : strings('mrs');

    return (
      <div id={'redirect'}>
        <div className={'header'}>
          <img className={'epa-logo'} src={images.epaLogo} alt={'epa-logo'} />
          <p className={'label'}>{strings('identification')}</p>
        </div>
        <img
          className={'epa-background'}
          src={images.epaBackground}
          alt={'EPA-background'}
        />

        <div className={'user-infos'}>
          {user.role !== userRole.STUDENT && (
            <p>{`${civility} ${user.firstName} ${user.lastName}`}</p>
          )}
          <p className={'user-role'}>
            {strings('connected_as')}
            {this.getRole(user.role)}
          </p>
        </div>
        <i className={'material-icons logout'} onClick={logout}>
          {icons.LOGOUT}
        </i>
        <div className={'redirection-wrapper'}>
          <p className={'title'}>{strings('welcome')}</p>
          <hr className={'separator'} />
          <div className={'message-wrapper'}>
            <p>{strings('redirect_message')}</p>
          </div>
          <div className={'action-wrapper'}>
            {hasUserAccessToDashboard && (
              <Button
                color={ButtonColors.blue}
                label={strings('dashboard')}
                className={'dashboard'}
                action={() => this.handleRedirection(origins.DASHBOARD)}
              />
            )}
            {hasUserAccessToBO && (
              <Button
                color={ButtonColors.blue}
                label={strings('bo')}
                className={'bo'}
                action={() => this.handleRedirection(origins.FACILITATOR)}
              />
            )}
            <Button
              color={ButtonColors.green}
              label={strings('kumulus')}
              className={'kumulus'}
              action={() => this.handleRedirection(origins.KUMULUS)}
            />
          </div>
        </div>
      </div>
    );
  }
}
