import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { logger } from '../../../utils';
import './iconInput.scss';

export class IconInput extends PureComponent {

  constructor(props) {
    super(props);
    this.input = React.createRef();
  }

  state = {
    forceFloat: false,
  };

  componentDidMount() {
    const {defaultValue} = this.props;
    this.setState({forceFloat: defaultValue});
  }

  valueChanged = event => {
    const {value} = event.target;
    const {tag, handleValueChanged} = this.props;

    handleValueChanged(tag, value);
  };

  revealText = () => {
    const {type} = this.props;
    if (type === 'password') {
      this.input.current.type = 'text';
    }
  };

  hideText = () => {
    const {type} = this.props;
    if (type === 'password') {
      this.input.current.type = 'password';
    }
  };

  render() {
    const {label, placeholder, type, className, inputClassName, defaultValue, maxLength, error, icon} = this.props;

    return (
      <div className={`icon-input ${className}`}>
        <span>{label}</span>
        <div className={`input-wrapper ${inputClassName}`}>
          <input
            type={type}
            ref={this.input}
            placeholder={placeholder}
            defaultValue={defaultValue ? defaultValue : ''}
            maxLength={maxLength ? maxLength : ''}
            onChange={this.valueChanged}
          />
          {icon && <i className={`material-icons ${type === 'password' ? 'action' : ''}`}
                      onMouseDown={this.revealText}
                      onMouseUp={this.hideText}
                      onMouseLeave={this.hideText}>{icon}</i>}
        </div>
        { error && <span className={'error'}>{error}</span> }
      </div>
    );
  }
}

IconInput.propsType = {
  label: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  handleValueChanged: PropTypes.func,
};

IconInput.defaultProps = {
  type: 'text',
  handleValueChanged: () => logger.warn('handleValueChanged not implemented.'),
};


