/* Service utils */
import axios from 'axios';

/** Method types */
export const methods = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'PATCH',
  DELETE: 'delete',
};

export const fetch = ({
  method,
  url,
  data = {},
  params = {},
  headers = {},
  responseType,
}) => {
  let instance = axios.create();
  instance.defaults.headers.common = {};
  const { cancelToken } = params;
  delete params.cancelToken;

  return instance({
    baseURL: process.env.REACT_APP_BASE_API_URL,
    method: method,
    url: url,
    data: data,
    params: params,
    cancelToken: cancelToken,
    responseType,
    headers: {
      ...headers,
      'X-API-KEY': process.env.REACT_APP_API_KEY,
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(response => {
      return Promise.all([null, response]);
    })
    .catch(error => {
      // Fix proposed by TNU when error.response = undefined (for NetworkError)
      if (error.response) {
        return Promise.resolve([error]);
      }
      return Promise.resolve([{ response: { status: 500 } }]);
    });
};
export const getMaintenance = () => {
  return process.env.REACT_APP_MAINTENANCE === 'true';
};
