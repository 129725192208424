import React, { PureComponent } from 'react';
import { EMAIL_REGEXP } from '../../../../utils';
import { Button, ButtonColors, IconInput } from '../../../components';
import './form.scss';

export class Form extends PureComponent {

  state = {
    email: null,
    emailInvalid: false,
    resetPasswordTriggered: false,
  };

  getValidityClass = isInvalid => {
    return (this.state.resetPasswordTriggered && isInvalid) ? 'invalid' : '';
  };

  handleKeyPressed = (e) => {
    if ((e.keyCode ? e.keyCode : e.which) === 13) {
      e.preventDefault();
      const {email} = this.state;
      this.handleResetPassword(email);
    }
  };

  handleResetPassword = (email) => {
    const {action} = this.props;
    const emailInvalid = !EMAIL_REGEXP.test(email);

    this.setState({
      resetPasswordTriggered: true,
      emailInvalid,
    });

    if (!emailInvalid) {
      action(email);
    }
  };

  handleValueChanged = (tag, value) => {
    this.resetValidator();
    this.setState({[tag]: value});
  };

  resetValidator = () => {
    if (this.state.resetPasswordTriggered) {
      this.setState({
        email: false,
        resetPasswordTriggered: false,
      });
    }
  };

  render() {
    const {strings} = this.props;
    const {email, emailInvalid} = this.state;

    return (
      <form className={'form-wrapper'} onKeyPress={this.handleKeyPressed}>
        <IconInput
          label={strings('email')}
          type={'email'}
          tag={'email'}
          inputClassName={`email ${this.getValidityClass(emailInvalid)}`}
          handleValueChanged={this.handleValueChanged}
        />
        <Button
          color={ButtonColors.green}
          label={strings('reset')}
          action={() => this.handleResetPassword(email)}
        />
      </form>
    );
  }
}
