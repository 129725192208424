import {DropDown} from "./DropDown"
import {getTranslate} from "react-localize-redux";
import {connect} from 'react-redux'

const mapStateToProps = (state) => ({
  strings: getTranslate(state.locale)
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DropDown)
