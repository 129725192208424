import * as Sentry from '@sentry/browser';
import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { version } from '../package.json';
import { Router, store } from './core';
import './index.css';
import { unregister } from './registerServiceWorker';
import { Loader, SnackBar } from './ui/components';
import { MaintenanceMode } from './ui/screens';
import { loadState, saveState } from './utils';
import { getMaintenance } from './utils/service';
import TagManager from 'react-gtm-module'
if (loadState('epa_bo_last-version') !== version) {
  saveState('epa_bo_last-version', version);
  window.location.reload();
}

Sentry.init({
  dsn: 'https://91481538487141cf8db659d984ed92e8@sentry.io/1858201',
});

  TagManager.initialize({ gtmId: 'GTM-5F36KT7' });

ReactDOM.render(
  getMaintenance() ? (
    <MaintenanceMode />
  ) : (
    <Provider store={store}>
      <Fragment>
        <Router />
        <SnackBar />
        <Loader />
      </Fragment>
    </Provider>
  ),
  document.getElementById('root')
);

unregister();
