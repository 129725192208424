import { call, put, takeLatest } from 'redux-saga/effects';
import { RegionsService } from '../../services';
import { LoaderActions } from '../loader';
import { default as RegionsActions, types } from './actions';

function* regionsRequested({inBackground}) {
  if (!inBackground) {
    yield put(LoaderActions.loading());
  }
  const [error, response] = yield call(RegionsService.getRegions);
  if (!error) {
    yield put(RegionsActions.regionsSucceeded(response.data));
  }
  //TODO: else show error ?

  if (!inBackground) {
    yield put(LoaderActions.loaded());
  }
}

export default [
  takeLatest(types.REGIONS_REQUESTED, regionsRequested),
];
