import React from 'react';
import { icons } from '../../../assets';
import './snackBar.scss';

export const SnackBar = ({
  isShown,
  message,
  level,
  forceHide,
  snackAction,
  actionMessage,
  onAction,
}) => {
  return (
    <div
      id="infoView"
      className={isShown ? 'display-info' : 'hide-info'}
      style={{ backgroundColor: level }}
    >
      <div className="message">{message}</div>
      {snackAction && (
        <div className={'action-button'} onClick={onAction}>
          {actionMessage}
        </div>
      )}
      <i className="material-icons remove-info" onClick={forceHide}>
        {icons.CLOSE}
      </i>
    </div>
  );
};
