import { UNAUTHORIZED } from 'http-status-codes';
import { call, put, select } from 'redux-saga/effects';
import { default as AuthActions } from '../redux/auth/actions';
import { default as AuthorizeService } from './authorize';
import { fetch } from '../utils';

function* authorize(service) {
  const {refreshToken, accessToken} = yield select(state => state.auth);
  service.headers = {...service.headers, Authorization: `Bearer ${accessToken}`};
  let [error, response] = yield call(fetch, service);

  if (error && error.response.status === UNAUTHORIZED) {
    const [refreshError, refreshResponse] = yield call(
      AuthorizeService.refreshTokens, refreshToken,
    );

    if (refreshError) {
      yield put(AuthActions.logoutRequested());
      return [refreshError];
    }

    yield put(AuthActions.refreshTokensSucceed(
      refreshResponse.data.accessToken,
      refreshResponse.data.refreshToken,
    ));

    [error, response] = yield call(authorize, service);
  }

  return [error, response];
}

export function* authenticatedService(method,
                                      url,
                                      data = {},
                                      params = {},
                                      headers = {},
                                      responseType = '',
                                      ) {
  return yield call(authorize, {
    method,
    url,
    data,
    params,
    headers,
    responseType,
  });
}

export function* service(method,
                         url,
                         data = {},
                         params = {},
                         headers = {},
                         responseType = '') {
  return yield call(fetch, {
    method,
    url,
    data,
    params,
    headers,
    responseType,
  });
}

