export const Routes = {
  DEFAULT_ROUTE: '/',
  RESET_PASSWORD: '/reset-password',
  CHANGE_PASSWORD: '/change-password',
  INIT_PASSWORD: '/init-password',
  REGISTER: '/register',
  REDIRECT: '/redirect',
};

export const origins = {
  AUTHENT: 'A',
  KUMULUS: 'K',
  DASHBOARD: 'D',
  FACILITATOR: 'F',
};

export const errorCodes = {
  FORBIDDEN: {
    ACCESS_DENIED: 'AccessDenied',
    INVALID_REDIRECTION: 'InvalidRedirection',
  },
};

export const userAccessStatus = {
  PENDING: 'PENDING',
  FREEMIUM: 'FREEMIUM',
  PREMIUM: 'PREMIUM',
};

export const userRole = {
  ADMIN: 'ADMIN',
  FACILITATOR: 'FACILITATOR',
  SUPERVISOR: 'SUPERVISOR',
  MENTOR: 'MENTOR',
  STUDENT: 'STUDENT',
};

export const civility = {
  MR: 'Monsieur',
  MME: 'Madame',
};

export const civilityList = [civility.MME, civility.MR];

export const civilityValues = {
  MR: 'MR',
  MME: 'MME',
};

export const actions = {
  LOGOUT: 'logout',
};
