import { createAction } from '../../utils';

// Types
export const types = {
  LOGIN_REQUESTED: 'LOGIN_REQUESTED',
  LOGIN_SUCCEED: 'LOGIN_SUCCEED',
  GENERATE_TOKENS_REQUEST: 'GENERATE_TOKENS_REQUEST',
  LOGOUT_REQUESTED: 'LOGOUT_REQUESTED',
  CHANGE_PASSWORD_REQUESTED: 'CHANGE_PASSWORD_REQUESTED',
  INIT_PASSWORD_REQUESTED: 'INIT_PASSWORD_REQUESTED',
  RESET_PASSWORD_REQUESTED: 'RESET_PASSWORD_REQUESTED',
  REFRESH_TOKENS_SUCCEED: 'REFRESH_TOKENS_SUCCEED',
  REGISTER_REQUESTED: 'REGISTER_REQUESTED',
};

// Actions
export default {
  loginRequested: (login, password, origin, redirectUrl) => createAction(types.LOGIN_REQUESTED, {
    login,
    password,
    origin,
    redirectUrl,
  }),
  loginSucceed: (id, accessToken, refreshToken) => createAction(types.LOGIN_SUCCEED, {
    id,
    accessToken,
    refreshToken,
  }),
  generateTokens: (domain, redirectUrl, comeFromLogin) => createAction(types.GENERATE_TOKENS_REQUEST, {

    domain,
    redirectUrl,
    comeFromLogin
  }),
  logoutRequested: () => createAction(types.LOGOUT_REQUESTED),
  changePasswordRequested: (token, password) => createAction(types.CHANGE_PASSWORD_REQUESTED, {
    token,
    password,
  }),
  initPasswordRequested: (token, password) => createAction(types.INIT_PASSWORD_REQUESTED, {
    token,
    password,
  }),
  resetPasswordRequested: (email, callFrom) => createAction(types.RESET_PASSWORD_REQUESTED, {
    email,
    callFrom
  }),
  refreshTokensSucceed: (accessToken, refreshToken) => createAction(types.REFRESH_TOKENS_SUCCEED, {
    accessToken,
    refreshToken,
  }),
  registerRequested: form => createAction(types.REGISTER_REQUESTED, {form}),
};
