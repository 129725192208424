import React, { PureComponent } from 'react';
import {
  actions,
  getQueryParamsFromUrl,
  getRedirectUrlFromOrigin,
  Routes,
} from '../../../../utils';
import { AuthWrapper, Button, ButtonColors } from '../../../components';
import { Form as LoginForm } from './Form';

export class Login extends PureComponent {

  componentDidMount() {
    const {isUserAuthenticated, logout, navigateTo, generateTokens} = this.props;
    let {action, origin, redirectUrl} = getQueryParamsFromUrl(window.location.href);

    if (action && action === actions.LOGOUT) {
      logout();
      return;
    }

    if (isUserAuthenticated) {
      if (origin) {
        if (!redirectUrl) {
          redirectUrl = getRedirectUrlFromOrigin(origin);
        }
        generateTokens(origin, redirectUrl);
      } else {
        navigateTo(Routes.REDIRECT);
      }
    }
  };

  handleLogin = (login, password) => {
    let {origin, redirectUrl} = getQueryParamsFromUrl(window.location.href);

    if (!origin) origin = null;
    if (origin && !redirectUrl) {
      redirectUrl = getRedirectUrlFromOrigin(origin);
    } else if (!redirectUrl) {
      redirectUrl = null;
    }

    this.props.login(login, password, origin, redirectUrl);
  };

  handleCreateAccount = () => {
    this.props.navigateTo(Routes.REGISTER);
  };

  render() {
    const { strings } = this.props;
   
    return (
      <AuthWrapper
        label={strings('identification')}
        title={strings('login')}
        className={'login-form'}
      >
        <LoginForm
          action={this.handleLogin}
          strings={strings}
        />
        <a className={'link'} href={Routes.RESET_PASSWORD}>
          {strings('forgotten_password')}
        </a>
        <hr className={'separator'}/>
        <div className={'inscription'}>
          <Button
            color={ButtonColors.blue}
            label={strings('create_account')}
            className={'create-account'}
            action={this.handleCreateAccount}
          />
        </div>
      </AuthWrapper>
    );
  }
}

