import { call, put, select, takeLatest } from 'redux-saga/effects';
import { UserService } from '../../services';
import { LoaderActions } from '../loader';
import { SnackActions } from '../snackBar';
import { default as UserActions, types } from './actions';

function* userRequest() {
  yield put(LoaderActions.loading());
  const {id} = yield select(state => state.auth);
  const [error, response] = yield call(UserService.getUser, id);
  if (!error) {
    yield put(UserActions.userFetched(response.data));
  } else {
    yield put(SnackActions.displayError('profile_retrieval_failed'));
  }
  yield put(LoaderActions.loaded());
}

export default [
  takeLatest(types.USER_REQUEST, userRequest),
];
