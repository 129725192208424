import {createReducer} from 'reduxsauce';
import { types } from './actions';

const initialState = {
  regions: [],
};

const regionsSucceeded = (state = initialState, action) => {
  const {regions} = action;
  return {...state, regions: regions};
};


export default createReducer(initialState, {
  [types.REGIONS_SUCCEEDED]: regionsSucceeded,
});
