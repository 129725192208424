import { createAction } from '../../utils/index';

// Types
export const types = {
  REGIONS_REQUESTED: 'REGIONS_REQUESTED',
  REGIONS_SUCCEEDED: 'REGIONS_SUCCEEDED',
};

// Actions
export default {
  regionsRequested: (inBackground = true) => createAction(types.REGIONS_REQUESTED, {inBackground}),
  regionsSucceeded: regions => createAction(types.REGIONS_SUCCEEDED, {regions}),
};


