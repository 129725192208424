import React, { PureComponent } from 'react';
import { icons } from '../../../../assets';
import { EMAIL_REGEXP, civilityList, civilityValues } from '../../../../utils';
import { Button, ButtonColors, DropDown, IconInput } from '../../../components';
import './form.scss';
import Cgu from "../../../components/cgu/Cgu";

export class Form extends PureComponent {
  state = {
    civility: civilityValues.MME,
    firstName: null,
    lastName: null,
    phone: null,
    email: null,
    region: null,
    firstNameInvalid: false,
    lastNameInvalid: false,
    emailInvalid: false,
    regionInvalid: false,
    registerTriggered: false,
  };

  getValidityClass = isInvalid => {
    return this.state.registerTriggered
      ? isInvalid
        ? 'invalid'
        : 'valid'
      : '';
  };

  handleKeyPressed = e => {
    if ((e.keyCode ? e.keyCode : e.which) === 13) {
      e.preventDefault();
      const { civility, firstName, lastName, phone, email, region } =
        this.state;
      this.handleRegister(civility, firstName, lastName, phone, email, region);
    }
  };

  handleRegister = (civility, firstName, lastName, email, region) => {
    const { action } = this.props;
    const firstNameInvalid = !firstName || firstName.length === 0;
    const lastNameInvalid = !lastName || lastName.length === 0;
    const emailInvalid = !email || !EMAIL_REGEXP.test(email);
    const regionInvalid = !region;

    this.setState({
      registerTriggered: true,
      firstNameInvalid,
      lastNameInvalid,
      emailInvalid,
      regionInvalid,
    });

    if (
      !firstNameInvalid &&
      !lastNameInvalid &&
      !emailInvalid &&
      !regionInvalid
    ) {
      action({ civility, firstName, lastName, email, region });
    }
  };

  handleValueChanged = (tag, value) => {
    const { strings } = this.props;

    if (value === strings('mr')) value = civilityValues.MR;
    if (value === strings('mrs')) value = civilityValues.MME;

    this.resetValidator();
    this.setState({ [tag]: value });
  };

  resetValidator = () => {
    if (this.state.registerTriggered) {
      this.setState({
        firstNameInvalid: false,
        lastNameInvalid: false,
        emailInvalid: false,
        regionInvalid: false,
        registerTriggered: false,
      });
    }
  };

  render() {
    const { strings, regions } = this.props;
    const {
      civility,
      firstName,
      lastName,
      email,
      region,
      firstNameInvalid,
      lastNameInvalid,
      emailInvalid,
      regionInvalid,
    } = this.state;

    return (
      <form id={'registerForm'} onKeyPress={this.handleKeyPressed}>
        <div className={'civility-container'}>
          <DropDown
            label={'civility'}
            current={civility}
            data={civilityList}
            className={`civility`}
            handleClickItem={item =>
              this.handleValueChanged('civility', item.value)
            }
          />
          <IconInput
            label={strings('firstName')}
            type={'text'}
            tag={'firstName'}
            className={'firstName'}
            inputClassName={this.getValidityClass(firstNameInvalid)}
            handleValueChanged={this.handleValueChanged}
          />
          <IconInput
            label={strings('lastName')}
            type={'text'}
            tag={'lastName'}
            className={'lastName'}
            inputClassName={this.getValidityClass(lastNameInvalid)}
            icon={icons.PERSON}
            handleValueChanged={this.handleValueChanged}
          />
        </div>
        <IconInput
          label={strings('email')}
          type={'email'}
          tag={'email'}
          className={'email'}
          inputClassName={this.getValidityClass(emailInvalid)}
          handleValueChanged={this.handleValueChanged}
        />
        <DropDown
          label={'region'}
          data={regions}
          current={region ? region : strings('select_region')}
          className={`region ${this.getValidityClass(regionInvalid)}`}
          handleClickItem={item =>
            this.handleValueChanged('region', item.value)
          }
          disabled={regions.length === 0}
        />
        <Cgu />
        <Button
          color={ButtonColors.green}
          label={strings('validate')}
          action={() =>
            this.handleRegister(civility, firstName, lastName, email, region)
          }
        />
      </form>
    );
  }
}
