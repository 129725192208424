import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { AuthActions } from '../../../../redux/auth';
import { RegionsActions, RegionsSelector } from '../../../../redux/regions';
import { Register } from './Register';

const mapStateToProps = state => ({
  strings: getTranslate(state.locale),
  regions: RegionsSelector.getRegions(state),
});

const mapDispatchToProps = dispatch => ({
  register: form => dispatch(AuthActions.registerRequested(form)),
  getRegions: () => dispatch(RegionsActions.regionsRequested(false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
