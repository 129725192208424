import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { AuthActions, AuthSelectors } from '../../../../redux/auth';
import { Login } from './Login';

const mapStateToProps = state => ({
  strings: getTranslate(state.locale),
  isUserAuthenticated: AuthSelectors.isUserAuthenticated(
    state.auth.accessToken
  ),
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(AuthActions.logoutRequested()),
  login: (login, password, origin, redirectUrl) =>
    dispatch(AuthActions.loginRequested(login, password, origin, redirectUrl)),
  generateTokens: (domain, redirectUrl) =>
    dispatch(AuthActions.generateTokens(domain, redirectUrl, true)),
  navigateTo: path => dispatch(push(path)),

});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
