import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { SnackActions } from '../../../redux/snackBar';
import { SnackBar } from './SnackBar';

const mapStateToProps = state => ({
  message: getTranslate(state.locale)(state.snackBar.message),
  actionMessage: state.snackBar.actionMessage
    ? getTranslate(state.locale)(state.snackBar.actionMessage)
    : null,
  snackAction: state.snackBar.snackAction,
  level: state.snackBar.level,
  isShown: state.snackBar.displayed,
});

const mapDispatchToProps = dispatch => ({
  onAction: () => dispatch(SnackActions.onAction()),
  forceHide: () => dispatch(SnackActions.hideSnackBar()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SnackBar);
