import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { AuthActions } from '../../../redux/auth';
import { UserActions, UserSelectors } from '../../../redux/user';
import { Redirect } from './Redirect';

const mapStateToProps = state => ({
  strings: getTranslate(state.locale),
  hasUserAccessToDashboard: UserSelectors.hasUserAccessToDashboard(state),
  hasUserAccessToBO: UserSelectors.hasUserAccessToBO(state),
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  userRequest: () => dispatch(UserActions.userRequest()),
  generateTokens: (domain, redirectUrl) => dispatch(AuthActions.generateTokens(domain, redirectUrl, false)),
  logout: () => dispatch(AuthActions.logoutRequested()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Redirect);
