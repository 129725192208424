export const addParamToUrl = (url, ...params) => {
  let urlWithparams = url;

  const isNotFirstParam = urlWithparams.includes("?");
  params.forEach((param, index) => {
    if (param.key && param.value) {
      urlWithparams+= `${isNotFirstParam || index > 0 ? '&' : '?'}${param.key}=${param.value}`
    }
  });

  return urlWithparams;
};

export const getQueryParamsFromUrl = (url) => {
  const paramsObject = {};
  const paramsString = url.substring(url.indexOf('?') + 1, url.length);

  let startIndex = 0;
  let index;
  while ((startIndex < paramsString.length && paramsString.indexOf('&', startIndex)) > -1) {
    index = paramsString.indexOf('&', startIndex);
    const param = paramsString.substring(startIndex, index);
    const separator = param.indexOf('=');

    const key = param.substring(0, separator);
    paramsObject[key] = param.substring(separator + 1, param.length);

    startIndex = index + 1;
  }

  const lastParam = paramsString.substring(startIndex, paramsString.length);
  const separator = lastParam.indexOf('=');

  const key = lastParam.substring(0, separator);
  paramsObject[key] = lastParam.substring(separator + 1, lastParam.length);

  return paramsObject;
};
