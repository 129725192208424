import * as qs from 'qs';
import React, { PureComponent } from 'react';
import { AuthWrapper } from '../../../components';
import { Form as ChangePasswordForm } from './Form';

export class ChangePassword extends PureComponent {

  handleChangePassword = password => {
    const {changePassword, initPassword, location} = this.props;
    const { invitationToken, temporaryToken } = qs.parse(location.search, { ignoreQueryPrefix: true });

    if (invitationToken) {
      initPassword(invitationToken, password);
    } else if (temporaryToken) {
      changePassword(temporaryToken, password);
    }
  };

  render() {
    const {strings} = this.props;
    return (
      <AuthWrapper
        label={strings('new_password')}
        title={strings('new_password')}
        className={'change-password-form'}
      >
        <ChangePasswordForm
          action={this.handleChangePassword}
          strings={strings}
        />
      </AuthWrapper>
    );
  }
}
