/* Displays a custom logger in dev mode only */

const __DEV__ = process.env.NODE_ENV === 'development';
export const logger = {
  trace: (message, ...optionalParams) => {
    if (__DEV__) {
      console.trace('%cTRACE%c', 'color: #9E9E9E; font-weight: bold', '', message, ...optionalParams);
    }
  },
  debug: (message, ...optionalParams) => {
    if (__DEV__) {
      console.debug('%cDEBUG%c', 'color: #4CAF50; font-weight: bold', '', message, ...optionalParams);
    }
  },
  info: (message, ...optionalParams) => {
    if (__DEV__) {
      console.log('%cINFO%c', 'color: #2196F3; font-weight: bold', '', message, ...optionalParams);
    }
  },
  warn: (message, ...optionalParams) => {
    if (__DEV__) {
      console.warn('%cWARN%c', 'color: #FF9800; font-weight: bold', '', message, ...optionalParams);
    }
  },
  error: (message, ...optionalParams) => {
    if (__DEV__) {
      console.error('%cERROR%c', 'color: #F44336; font-weight: bold', '', message, ...optionalParams);
    }
  },
};