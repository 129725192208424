import React, { PureComponent } from 'react';
import { icons } from '../../../../assets';
import { Button, ButtonColors, IconInput } from '../../../components';
import './form.scss';

export class Form extends PureComponent {

  state = {
    password: null,
    passwordBis: null,
    passwordInvalid: false,
    changePasswordTriggered: false,
  };

  getValidityClass = isInvalid => {
    return this.state.changePasswordTriggered ? isInvalid ? 'invalid' : 'valid' : '';
  };

  handleKeyPressed = (e) => {
    if ((e.keyCode ? e.keyCode : e.which) === 13) {
      e.preventDefault();
      const {password, passwordBis} = this.state;
      this.handleChangePassword(password, passwordBis);
    }
  };

  handleChangePassword = (password, passwordBis) => {
    const {action} = this.props;
    const passwordInvalid = !password || password.length < 7 || password !== passwordBis;

    this.setState({
      changePasswordTriggered: true,
      passwordInvalid,
    });

    if (!passwordInvalid) {
      action(password);
    }
  };

  handleValueChanged = (tag, value) => {
    this.resetValidator();
    this.setState({[tag]: value});
  };

  resetValidator = () => {
    if (this.state.changePasswordTriggered) {
      this.setState({
        passwordInvalid: false,
        changePasswordTriggered: false,
      });
    }
  };

  render() {
    const {strings} = this.props;
    const {password, passwordBis, passwordInvalid} = this.state;

    return (
      <form className={'form-wrapper'} onKeyPress={this.handleKeyPressed}>
        <IconInput
          label={strings('password')}
          type={'password'}
          className={'password'}
          inputClassName={`${this.getValidityClass(passwordInvalid)}`}
          error={passwordInvalid && (!password || password.length < 7) ? strings('short_password') : null}
          tag={'password'}
          icon={icons.EYE}
          handleValueChanged={this.handleValueChanged}
        />
        <IconInput
          label={strings('confirm_password')}
          type={'password'}
          tag={'passwordBis'}
          icon={icons.EYE}
          className={'passwordBis'}
          inputClassName={`${this.getValidityClass(passwordInvalid)}`}
          error={passwordInvalid && password && password.length >= 7 ? strings('password_difference') : null}
          handleValueChanged={this.handleValueChanged}
        />
        <Button
          color={ButtonColors.green}
          label={strings('validate')}
          action={() => this.handleChangePassword(password, passwordBis)}
        />
      </form>
    );
  }
}
