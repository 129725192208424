import React from 'react';
import { images } from '../../../../assets';
import './wrapper.scss';

export const Wrapper = ({children, label, title, className}) => {
  return (
    <div id={'authWrapper'} className={className}>
      <div className={'header'}>
        <img
          className={'epa-logo'}
          src={images.epaLogo}
          alt={'epa-logo'}
        />
        <p className={'label'}>{label}</p>
      </div>
      <img className={'epa-background'} src={images.epaBackground} alt={'EPA-background'}/>
      <div id={'formContainer'}>
        <p className={'title'}>{title}</p>
        <hr className={'separator'}/>
        <div id={'form'}>
          {children}
        </div>
      </div>
    </div>
  );
};
