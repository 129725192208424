
export default {
  /* Auth */
  identification: "Identification",
  login: "Saisissez vos identifiants",
  connexion: "Se connecter",
  identifier: "Identifiant",
  email: "Identifiant projet ou email",
  password: "Mot de passe",
  access: "Accéder",
  forgotten_password: "Mot de passe oublié",
  create_account: "Créer un compte gratuit",
  reset: "Réinitialiser",
  reset_password: "Réinitialiser le mot de passe",
  new_password: "Nouveau mot de passe",
  confirm_password: "Confirmation mot de passe",
  short_password: "Le mot de passe doit faire au moins 7 caractères de long",
  password_difference: "Les deux mots de passe ne sont pas identiques",
  bad_credentials: "email ou mot de passe incorrect",
  email_already_exists:
    'Cet email est déjà enregistré chez nous, veuillez vous connecter avec votre email et mot de passe ou réinitialisez votre mot de passe &nbsp;&nbsp;&nbsp; <a href="/" style="color:#ffffff;text-decoration:none; font-size:16px; border:1px solid #ffffff; border-radius:5px; padding:5px;">Se connecter</a>',
  connected_as: "Connecté en tant que : ",
  administrator: "administrateur",
  mentor: "Mentor",
  supervisor: "Encadrant",
  student: "Étudiant",
  facilitator: "Facilitateur",
  admin: "Administrateur",
  mr: "Monsieur",
  mrs: "Madame",

  /* Register */
  register: "Création de compte",
  information: "Vos informations",
  civility: "Civilité",
  firstName: "Prénom",
  lastName: "Nom",
  phone: "Numéro de téléphone (facultatif)",
  region: "Région",
  select_region: "Sélectionnez votre région",
  validate: "Valider",

  /* Redirect */
  welcome: "Bienvenue sur la plateforme EPA",
  redirect_message:
    "Votre compte vous permet d'accéder à la plateforme des Tableaux de bord de projet et à la plateforme pédagogique Kumulus Air",
  kumulus: "Playlist Vidéos",
  dashboard: "Tableau de bord",
  bo: "Back-Office",

  /* SnackBar Message */
  login_failed: "Une erreur est survenue lors de la tentative de connexion",
  auth_failed:
    "Une erreur est survenue lors de la tentative d'authentification",
  invalid_redirect: "Redirection non authorisée",
  access_forbidden:
    "Votre profil ne vous permet actuellement pas d'accéder à cette plateforme",
  reset_succeeded:
    "Votre demande de réinitialisation de mot de passe a été prise en compte. Vous recevrez un email pour vous indiquer la démarche à suivre",
  reset_failed: "Votre demande de réinitialisation de mot de passe n'a pas aboutie. Merci de réessayer ultérieurement.",
  reset_password_link_expired:
      `Le délai de création de votre mot de passe est dépassé. Merci de compléter ce formulaire pour réinitialiser votre mot de passe.`,
  profile_retrieval_failed:
    "Une erreur est survenue lors de la récupération de votre profile",
  register_failed:
    "Votre inscription n'a pas pu être prise en compte, veuillez réessayer plus tard",
  register_succeeded:
    "Votre inscription a bien été prise en compte. Vous allez recevoir un e-mail.",

  generate_succeed: "Génération de tokens OK",
};
