import { createReducer } from 'reduxsauce';
import { types } from './actions';

const initialState = {
  accessToken: null,
  refreshToken: null,
};

// region Reducers
const loginSuccess = (state = initialState, action) => {
  const {id, accessToken, refreshToken} = action;
  return {...state, id, accessToken, refreshToken};
};

const refreshTokens = (state = initialState, action) => {
  const {accessToken, refreshToken} = action;
  return {...state, accessToken, refreshToken};
};
// endregion

export default createReducer(initialState, {
  [types.LOGIN_SUCCEED]: loginSuccess,
  [types.REFRESH_TOKENS_SUCCEED]: refreshTokens,
});
