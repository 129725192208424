import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { AuthActions } from '../../../../redux/auth';
import { ChangePassword } from './ChangePassword';

const mapStateToProps = state => ({
  strings: getTranslate(state.locale),
});

const mapDispatchToProps = dispatch => ({
  changePassword: (token, password) => dispatch(
    AuthActions.changePasswordRequested(token, password),
  ),
  initPassword: (token, password) => dispatch(
    AuthActions.initPasswordRequested(token, password),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
