import { resettableReducer } from 'reduxsauce';
import { AuthReducers, AuthSagas } from './auth';
import { LoaderReducers } from './loader';
import { SnackReducers, SnackSagas } from './snackBar';
import { RegionsReducers, RegionsSagas } from './regions';
import { UserReducers, UserSagas } from './user';

// Specify reset action type rule
const resettable = resettableReducer('RESET_REDUCERS');

const reducers = Object.assign({},
  {
    auth: resettable(AuthReducers),
    regions: resettable(RegionsReducers),
    user: resettable(UserReducers),
    loader: resettable(LoaderReducers),
    snackBar: SnackReducers,
  });

export const rootSaga = [
  ...AuthSagas,
  ...RegionsSagas,
  ...UserSagas,
  ...SnackSagas,
];

export default reducers;
