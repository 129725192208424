import React, { PureComponent } from 'react';
import { AuthWrapper } from '../../../components';
import { Form as RegisterForm } from './Form';

export class Register extends PureComponent {
  componentDidMount() {
    this.props.getRegions();
    }

  render() {
    const { strings, regions, register } = this.props;
    return (
      <AuthWrapper
        label={strings('register')}
        title={strings('information')}
        className={'register-form'}
      >
        <RegisterForm action={register} strings={strings} regions={regions} />
      </AuthWrapper>
    );
  }
};

