import { createSelector } from 'reselect';
import { userRole, userAccessStatus } from '../../utils';

const user = state => state.user || {};

const hasUserAccessToDashboard = createSelector(
  user, (user) => {
    return user !== {} && user.role !== userRole.FREEMIUM && user.rights === userAccessStatus.PREMIUM;
  },
);

const hasUserAccessToBO = createSelector(
  user, (user) => {
    return user !== {} && (user.role === userRole.ADMIN || user.role === userRole.FACILITATOR);
  },
);

export default {
  hasUserAccessToDashboard,
  hasUserAccessToBO
}
