import {createSelector} from 'reselect';

const regions = state => state.regions.regions || [];

const getRegions = createSelector(
  regions, (regions) => regions.map(r => r.name).sort((a,b) => a.localeCompare(b))
);

export default {
  getRegions,
}
