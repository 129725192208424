import { connectRouter, routerMiddleware } from 'connected-react-router';
import { renderToStaticMarkup } from 'react-dom/server';
import { addTranslationForLanguage, initialize, localizeReducer } from 'react-localize-redux';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { languages, strings } from '../assets';
import appReducers, { rootSaga } from '../redux';
import { loadState, saveState } from '../utils';
import { history } from './history';

const sagaMiddleware = createSagaMiddleware();
const persistedState = loadState('epa_auth_state');

const middleware = [
  applyMiddleware(sagaMiddleware),
  applyMiddleware(routerMiddleware(history))]
  .concat(process.env.NODE_ENV === 'development' ? [
    window.__REDUX_DEVTOOLS_EXTENSION__
    && window.__REDUX_DEVTOOLS_EXTENSION__()
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : compose,
  ] : []);

const store = createStore(
  combineReducers({
    router: connectRouter(history),
    locale: localizeReducer,
    ...appReducers,
  }),
  persistedState,
  compose(...middleware),
);

// Each sagas are isolated
// This ensure that the sagas will not be canceled if one failed
// (Be aware that should be a source of performance leak)
rootSaga.forEach(saga => sagaMiddleware.run(function* () {
  yield saga;
}));

store.dispatch(initialize({
  languages: languages,
  translation: strings.french,
  options: {
    renderToStaticMarkup,
    renderInnerHtml: true,
    defaultLanguage: 'fr',
  },
}));
store.dispatch(addTranslationForLanguage(strings.french, 'fr'));
// Save state when changes occurred
store.subscribe(() => {
  const {auth, regions, user} = store.getState();
  saveState('epa_auth_state', {auth, regions, user});
});

export default store;
